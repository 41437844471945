body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loading-indicator:before {
  content: '';
  background: #33333333;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100000000;
}

.loading-indicator:after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 6px solid transparent;
  border-top-color: rgb(1, 181, 22);
  border-bottom-color: rgb(46, 195, 0);
  animation: spinner .7s ease infinite;
  z-index: 100000001;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.root-97 {
  /* height: 100%; */
  background-color: rgb(244, 244, 244);
  width: 99px;
  position: relative;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin: 15px;
}

.data-table th,
.data-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.data-table th {
  background-color: #f2f2f2;
}

.data-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.data-table tbody tr:hover {
  background-color: #e0e0e0;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.pagination {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.pagination-number,
.pagination-arrow {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  padding: 5px 10px;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #333;
}

.pagination-number:hover,
.pagination-arrow:hover {
  background-color: #e0e0e0;
}

.pagination-arrow.disabled {
  cursor: not-allowed;
  background-color: #ddd;
}

.pagination-number.active {
  background-color: #007bff;
  color: #fff;
}

a {
  text-decoration: none;
}

.uacontainer {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.unbody {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.unp {
  color: #666;
}

.unh1 {
  color: #ff6347;
}