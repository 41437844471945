.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    z-index: 999;
}

.popup-content {
    text-align: center;
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    /* background-color: #0056b3; */
    background-color: #cbe2fa;
}
  
  @media only screen and (max-width: 768px) {
    .dialogContainer {
      max-width: 90%;
      margin: 0 auto; 
    }
  }
  
  @media only screen and (max-width: 480px) {
    .dialogContainer {
      max-width: 80%;
      margin: 0 auto; 
    }
  }
  